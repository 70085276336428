<template>
  <div v-loading="loading" class="order-detail-container">
    <!-- <div class="buttonbc">
      <el-button @click="() => { $router.go(0); }" size="small" type="primary">刷新</el-button>
      <el-button @click="() => { $router.go(-1); }" size="small">关闭</el-button>
    </div> -->
    <div class="buttonbc">
      <el-button @click="() => { $router.go(-1); }" size="small" class="back_btn">
        <img src="@/assets/back.png" alt="">
      </el-button>
    </div>
    <div class="cont">
      <br />
      <br />
      <h4>基本信息</h4>
      <table class="table-cont">
        <tbody>
          <tr>
            <td class="details-div-span">
              交易状态：<span class="detail-span1">{{ orderDetail.trade_status_text }}</span>
              <span style="color: red;">
                {{ orderDetail.hangup_status === 1 ? '(店铺挂起)' :
                  (orderDetail.hangup_status === 2 ? '(企业挂起)' : '') }}
              </span>
            </td>
          </tr>
          <tr v-if="shopInfo.shop_type === 1 || shopInfo.shop_type === 3">
            <td>下单人信息：</td>
          </tr>
          <!-- 站点 -->
          <tr class="detail-tr1">
            <td>下单人姓名：{{ orderDetail.nickname }}</td>
            <td>下单人手机号：{{ orderDetail.mobile }}</td>
          </tr>
          <tr>
            <td>订单信息：</td>
          </tr>
          <tr class="detail-tr1">
            <td>用户下单时间：{{ orderDetail.create_time | unixToDate }}</td>
            <td>用户支付方式：{{ orderDetail.payment_method_name }}</td>
          </tr>
          <tr class="detail-tr1">
            <td>卡券卡号：{{ orderDetail.card_code }}</td>
            <td>
              订单总价：{{ orderDetail.shop_total_price | unitPrice("￥") }}
              (商品: {{ 0 | unitPrice("￥") }} + 运费: {{ orderDetail.shop_freight_price | unitPrice("￥") }})
            </td>
          </tr>
          <tr class="detail-tr1">
            <td>
              商城支付金额：{{ orderDetail.enterprise_goods_price + orderDetail.enterprise_freight_price | unitPrice("￥") }}
              (商品: {{ orderDetail.enterprise_goods_price | unitPrice("￥") }} + 运费: {{ orderDetail.enterprise_freight_price
                | unitPrice("￥") }})
            </td>
            <td>商城支付时间：{{ orderDetail.payment_time | unixToDate }}</td>
          </tr>
          <tr>
            <td>收货人信息：</td>
          </tr>
          <tr class="detail-tr1">
            <td>收货人：{{ orderDetail.consignee_name }}</td>
            <td>收货人手机号：{{ orderDetail.consignee_mobile }}</td>
          </tr>
          <tr class="detail-tr1">
            <td>
              收货地址：{{ orderDetail.consignee_province
              }}{{ orderDetail.consignee_city }} {{ orderDetail.consignee_county }}
              {{ orderDetail.consignee_town || "" }}{{ orderDetail.consignee_address }}
            </td>
          </tr>
          <tr>
            <td>
              备注信息：
            </td>
          </tr>
          <tr class="detail-tr1" style="display:inline-block; max-width: 25vw">
            <td v-if="orderDetail.remark">
              {{ orderDetail.remark }}
            </td>
            <td v-else>无</td>
          </tr>
        </tbody>
      </table>
      <br />
      <h4>商品信息</h4>
      <!-- 站点、供应商 -->
      <template v-if="shopInfo.shop_type !== 3">
        <el-table :data="productList" :header-cell-style="{ background: '#cccccc' }" :expand-row-keys="rowKeysList"
          row-key="sku_sn">
          <el-table-column prop="goods_image" label="商品详情" width="280">
            <template slot-scope="scope">
              <div style="display: flex; flex-direction: row; flex-wrap: nowrap;">
                <img :src="scope.row.goods_image" class="goods-image" style="margin-right: 15px;" />
                <el-tooltip class="item" effect="light" :content="scope.row.name" placement="top">
                  <el-button style="max-width: 19vw; overflow: hidden; text-overflow: ellipsis;" class="shop-name"
                    type="text">{{ scope.row.name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;') }}</el-button>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="goods_alias" label="商品前端名称">
            <template slot-scope="{row}">
              <span>{{ row.goods_alias ?
                row.goods_alias.replaceAll('amp;', '').replaceAll(' ', '&nbsp;') : row.name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;') }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="num" label="数量"></el-table-column>
          <el-table-column label="会员价" v-if="parentLogin === 1">
            <template slot-scope="scope">
              <span>{{ scope.row.platform_price | unitPrice("￥") }}</span>
            </template>
          </el-table-column>
          <!-- 站点展示销售价 -->
          <el-table-column label="销售价">
            <template slot-scope="scope">
              <span>{{ scope.row.shop_purchase_price | unitPrice("￥") }}</span>
            </template>
          </el-table-column>
          <!-- 站点展示分销价，供应商不展示分销价 -->
          <el-table-column label="分销价">
            <template slot-scope="scope">
              <span>{{ scope.row.enterprise_purchase_price | unitPrice("￥") }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="shop_total_price" label="物流状态">
            <template slot-scope="{row}">
              <span v-if="row.logistics_status_text">{{ row.logistics_status_text }}</span>
              <span v-else>{{ row.state === 0 ? '未发货' : (row.state === 2 ? '已发货' : '已收货') }}</span>
            </template>
          </el-table-column>
          <el-table-column label="京东单号" width="130">
            <template slot-scope="scope">
              <span v-if="scope.row.ext_order_id">{{ scope.row.ext_order_id }}</span>
              <span v-else>——</span>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <h4 v-if="expressInfo.length !== 0">包裹信息</h4>
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane v-for="(item, index) in expressInfo" :key="index" :label="item.message" :name="item.index">
          <p style="background-color: #6666; height: 30px; line-height: 30px;">
            <span>快递公司: {{ item.deliver_name }}</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span>物流单号: {{ item.delivery_no }}</span>
          </p>
          <div style="display: flex;">
            <div v-for="(i, index) in item.images" :key="index" style="width: 100px; height: 100px; margin-right: 20px;">
              <img :src="i" style="width: 100%; height: 100%;">
            </div>
          </div>
          <div class="logistics-info" v-if="item.data.code === 200">
            <p v-if="!item.data.data[0].express.data" style="display:block; line-height: 20px;">
              <!-- 判断物流是否超过三个月 expired==1（超过三个月） -->
              <span v-if="item.expired == 1">商家已发货，由于时间过长，已无法为您提供快递的物流信息</span>
              <span v-else>抱歉，暂时没有查到相关物流信息，请耐心等待。</span>
              <!-- <span v-else>{{ item.data.data[0].express.message || item.data.data[0].express }}</span> -->
            </p>
            <ul v-else>
              <li v-for="(ship, index) in item.data.data[0].express.data" :key="index" class="ship-item">
                <i class="ship-dot"></i>
                <div class="ship-msg">
                  <p class="ship-msg-text">{{ ship.context }}</p>
                  <p class="ship-msg-time">{{ ship.time }}</p>
                </div>
              </li>
            </ul>
          </div>
          <div class="logistics-info" v-else>
            <p v-if="!item.data.data.orderTrack" style="display:block; line-height: 20px;">{{ item.data.data.message }}
            </p>
            <ul v-else>
              <li v-for="(ship, index) in item.data.data.orderTrack" :key="index" class="ship-item">
                <i class="ship-dot"></i>
                <div class="ship-msg">
                  <p class="ship-msg-text">{{ ship.content }}</p>
                  <p class="ship-msg-time">{{ ship.msgTime }}</p>
                </div>
              </li>
            </ul>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog title="订单发货" :visible.sync="dialogVisible" width="70%" :before-close="handleClose">
      <div v-if="$store.getters.shopInfo.shop_type != 1" class="fhBox">
        <div class="wlbtnAll">
          <el-button type="primary" size="small" :disabled="!selectList.length || orderDetail.pay_status !== 'PAY_YES'"
            @click="submitFhing">确认发货</el-button>
        </div>
        <el-table border ref="multipleTable" :data="sku_list" style="width: 100%"
          @selection-change="handleSelectionChange"
          :header-cell-style="{ textAlign: 'center', backgroundColor: 'rgba(230, 236, 247, 1)' }">
          <el-table-column :selectable="checkboxT" type="selection" width="45"></el-table-column>
          <el-table-column prop="sku_sn" label="商品编号" width="180px"></el-table-column>
          <el-table-column prop="name" label="商品名称以及规格" min-width="120px">
            <template slot-scope="{row}">
              {{ row.goods_name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;') }}
            </template>
          </el-table-column>
          <el-table-column prop="num" label="商品数量"></el-table-column>
          <el-table-column label="物流公司" width="200px">
            <template slot-scope="scope">
              <el-select size="mini" :disabled="scope.row.state == 3" v-model="scope.row.logi_id" placeholder="请选择"
                @change="filterLogisticsData(scope.row)">
                <el-option v-for="item in logisticsData" :key="item.logi_id" :label="item.name"
                  :value="item.logi_id"></el-option>
              </el-select>
              <input type="text" style="display: none" v-model="scope.row.logi_name" />
            </template>
          </el-table-column>

          <el-table-column label="物流操作" width="260px">
            <template slot-scope="scope">
              <ul class="wlcz" style="list-style: none;">
                <li v-for="(item, index) in scope.row.delivery_list" :key="index"
                  style="display: flex; margin-bottom: 0;">
                  <el-input :disabled="scope.row.state == 3" size="mini" placeholder="请输入物流单号"
                    v-model="item.delivery_no"></el-input>
                </li>
              </ul>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <el-dialog title="操作" :visible.sync="dialogApply" :before-close="handleClose">
      <span>确定要转为售后订单吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogApply = false">取 消</el-button>
        <el-button type="primary" @click="getApply">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as API_order from "@/api/order";
import * as API_Goods from '@/api/goods';
import * as API_logistics from "@/api/expressCompany";
import * as Foundation from "@/../ui-utils/Foundation";
import { parse } from 'querystring';
export default {
  name: "orderDetail",
  computed: {
    ...mapGetters(["shopInfo"])
  },
  data () {
    return {
      rowKeysList: [],
      /** 列表loading状态 */
      loading: false,
      dialogApply: false,
      /** 订单详情数据 */
      orderDetail: {},
      productList: [],
      fhProductList: [],

      /** 物流信息 */
      logisticsData: [],
      /* 商品信息数组 */
      tableData: {},
      /*  快递公司ID和名称 */
      selectValue: "",
      /*  订单编号 */
      sn: "",
      /* 物流单号 */
      ship_no: "",
      route: "",
      // type: this.$route.query.type,
      expressInfo: [],
      activeName: '1',
      dialogVisible: false,
      /*订单商品数据*/
      sku_list: [],
      selectList: [],
      formInline: {
        logi_id: "",
      },
      applySaleParmas: {
        is_need_send: 0,
        order_sn: '',
        sku_id: '',
        refund_reason: '商家退款'
      },
      parentLogin: 1,
      type: 0
    };
  },
  filters: {
    paymentTypeFilter (val) {
      return val === "ONLINE" ? "在线支付" : "线下支付";
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.sn = to.params.sn;
    this.GET_OrderDetail();
    next();
  },
  mounted () {
    this.sn = this.$route.params.sn;
    this.type = this.$route.query.type
    this.GET_OrderDetail();
    this.route = this.$route.path.split("/")[2];
    this.getLogisticsCompanies()
    API_Goods.getLoginType().then((res) => {
      this.parentLogin = res.parentLogin;
    });
  },
  activated () {
    this.sn = this.$route.params.sn;
    this.GET_OrderDetail();
  },
  methods: {
    /** 获取物流公司信息列表 */
    getLogisticsCompanies () {
      API_logistics.getExpressCompanyList({}).then((res) => {
        this.logisticsData = res;
      });
    },
    deliverDialog (sn, ship_no) {
      this.dialogVisible = true;
      this.sn = sn;
      this.ship_no = ship_no;
      API_order.getOrderItemsList(sn).then((response) => {
        if (response && response.length) {
          let sku_list = [...response];
          sku_list.forEach((item) => {
            if (!item.delivery_list || !item.delivery_list.length || !item.delivery_list[0].delivery_no) {
              item.delivery_list = [{}];
            }
          });
          this.sku_list = sku_list;
        }
      });
      if (this.shopInfo.shop_type == 2) {
        API_order.getSellerOrderDetail(sn).then((response) => {
          // 订单信息
          if (response.order_status === 'CANCELLED' || response.service_status === 'APPLY') {
            this.$message.error('该订单已取消，不需要发货')
          }
          this.orderDetail = response;
        });
      } else {
        API_order.getOrderDetail(sn).then((response) => {
          // 订单信息
          if (response.order_status === 'CANCELLED' || response.service_status === 'APPLY') {
            this.$message.error('该订单已取消，不需要发货')
          }
          this.orderDetail = response;
        });
      }
    },
    handleClose () {
      this.dialogVisible = false;
      this.dialogApply = false
    },
    handleSelectionChange (list) {
      this.selectList = list;
      console.log(this.selectList, 'selectList')
    },
    checkboxT (row, index) {
      if (row.state === 3) {
        return false;
      } else {
        return true;
      }
    },
    submitFhing () {
      for (let i = 0; i < this.selectList.length; i++) {
        let index = i;
        let item = this.selectList[i];
        if (!item.logi_id) {
          this.$message.error("请选择物流公司");
          return false;
        }
        if (item.delivery_list) {
          for (let j = 0; j < item.delivery_list.length; j++) {
            let item1 = item.delivery_list[j];
            if (!item1.delivery_no) {
              this.$message.error("请输入物流单号");
              return false;
            }
          }
        }
      }
      this.$confirm("确认发货?", "提示", { type: "warning" }).then(() => {
        let fhInfo = {
          order_sn: this.sn,
          delivery_dolist: [],
        };
        this.selectList.forEach((item, index) => {
          item.delivery_list.forEach((item1, index1) => {
            fhInfo.delivery_dolist.push({
              logi_id: item.logi_id,
              logi_name: item.logi_name,
              order_sn: this.sn,
              sku_id: item.product_id,
              goods_id: item.goods_id,
              goods_name: item.name,
              goods_sn: item.sku_sn,
              ship_num: item.num,
              delivery_no: item1.delivery_no,
            });
          });
        });
        console.log(fhInfo)
        API_order.deliveryNew(fhInfo).then(() => {
          this.$message.success("发货成功");
          this.GET_OrderDetail()
          this.dialogVisible = false;
        });
      });
    },
    filterLogisticsData (row) {
      this.logisticsData.forEach((res) => {
        if (res.logi_id == row.logi_id) {
          row.logi_name = res.name;
        }
      });
    },

    fService_status (status) {
      switch (status) {
        case "NOT_APPLY":
          return "未申请";
        case "APPLY":
          return "已申请";
        case "PASS":
          return "审核通过";
        case "REFUSE":
          return "审核未通过";
        case "EXPIRED":
          return "已失效或不允许申请售后";
        case "REFUNDFAIL":
          return "退款失败";
        case "COMPLETED":
          return "完成";
        case "WAIT_SEND_BACK":
          return "待寄回";
        case "SEND_BACK":
          return "买家寄回";
        case "SELLER_SHIP":
          return "卖家发货";
        case "REFUSE_SHIP":
          return "卖家拒绝发货";
        default:
          return "";
      }
    },
    /** 获取订单详情信息 */
    GET_OrderDetail () {
      this.loading = true;
      if (this.shopInfo.shop_type === 2 || (this.shopInfo.shop_type === 3 && this.type === 2)) {
        API_order.getSellerOrderDetail(this.sn).then(res => {
          this.backFunc(res);
        });
      } else if (this.shopInfo.shop_type === 1) {
        API_order.getTradeInfo(this.sn).then(res => {
          console.log(res)
          this.backFunc(res);
        })
      } else {
        API_order.getTradeInfo(this.sn).then(res => {
          this.loading = false;
          // this.backFunc(res);
          this.productList = []
          this.orderDetail = res
          this.orderDetail['payment_method_name'] = this.orderDetail.payment_method_name.replace(/,/g, '+')
          this.orderDetail['remark'] = this.orderDetail.order[0].remark
          res.order.forEach(item => {
            item.sku_list.forEach(i => {
              i.ext_order_id = item.ext_order_id
              this.productList.push(i)
            })
          })
          //#region 加载物流信息 start
          let params = {}
          params['order_sn'] = this.sn
          params['sku_sn'] = this.productList[0]['sku_sn']
          API_order.getLogistics(params).then(res => {
            console.log(res)
            this.expressInfo = res
            this.expressInfo.forEach((item, index) => {
              item['message'] = '包裹' + String(index + 1)
              item['index'] = String(index + 1)
              if (item.data.code === 1) {
                item.data.data.orderTrack.reverse()
              }
            })
          })
          //#endregion 加载物流信息 end
        })
      }
    },

    async backFunc (res1) {
      this.loading = false;
      // 订单信息
      this.orderDetail = res1;
      this.orderDetail['payment_method_name'] = this.orderDetail.payment_method_name.replace(/,/g, '+')
      let res = await API_order.getOrderItemsList(this.sn);
      if (res && res.length) {
        let fhProductList = [...res];
        fhProductList.forEach(item => {
          if (!item.delivery_list || !item.delivery_list.length || !item.delivery_list[0].delivery_no) {
            item.delivery_list = [{}];
          }
        });
        this.fhProductList = fhProductList;
      }
      this.productList = this.orderDetail.order_sku_list
      if (this.shopInfo.shop_type === 1) {
        let list = []
        // 商城支付金额
        let enterprise_goods_price = 0
        this.orderDetail.order.forEach(item => {
          item.sku_list.forEach(i => {
            list.push(i)
            enterprise_goods_price += i.enterprise_purchase_price * i.num
          })
        })
        this.orderDetail['enterprise_goods_price'] = enterprise_goods_price
        this.productList = list
      } else {
        this.orderDetail.order_sku_list.forEach((item, idx) => {
          this.fhProductList.forEach((item1, idx1) => {
            if (item.sku_sn === item1.sku_sn) {
              this.orderDetail.order_sku_list[idx] = Foundation.deepClone({
                ...this.orderDetail.order_sku_list[idx],
                ...this.fhProductList[idx1]
              });
            }
          });
        });
      }
      this.productList.forEach(item => {
        if (!item.state) {
          item.state = 0
        }
      })
      let params = {}
      params['order_sn'] = this.sn
      params['sku_sn'] = this.productList[0]['sku_sn']
      if (this.shopInfo.shop_type == 2) {
        await API_order.getLogisticsOrder(params).then(res => {
          this.expressInfo = res
          this.expressInfo.forEach((item, index) => {
            item['message'] = '包裹' + String(index + 1)
            item['index'] = String(index + 1)
            if (item.data.code === 1) {
              item.data.data.orderTrack.reverse()
            }
          })
        })
      } else {
        await API_order.getLogistics(params).then(res => {
          this.expressInfo = res
          this.expressInfo.forEach((item, index) => {
            item['message'] = '包裹' + String(index + 1)
            item['index'] = String(index + 1)
            if (item.data.code === 1) {
              item.data.data.orderTrack.reverse()
            }
          })
        })
      }
      // ALL:所有订单,WAIT_PAY:待付款,WAIT_SHIP:待发货,WAIT_ROG:待收货,","CANCELLED:已取消,COMPLETE:已完成,WAIT_COMMENT:待评论,REFUND:售后中
    },
    haddlerApply (sku) {
      const order = this.orderDetail.order.filter(_order => _order.sku_list.filter(_sku => _sku.sku_id === sku.sku_id).length > 0)[0];

      this.applySaleParmas.order_sn = order.sn;
      this.applySaleParmas.sku_id = sku.sku_id;
      this.dialogApply = true;
    },

    // 转为售后订单
    getApply () {
      API_order.getApply(this.applySaleParmas).then(res => {
        console.log(res)
        if (res.refund_reason) {
          this.$message.success('操作成功')
          this.dialogApply = false
        }
      }).catch(() => {
        this.dialogApply = false
      })
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .wlcz {
  list-style: none;
  margin-bottom: 0;
  padding-left: 10px;
}

/deep/ .cell {
  text-align: center;
}

.logistics-info {
  background-color: #fff;
  padding: 10px 10px 50px 10px;

  // text-align: center;
  .ship-item {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    &:first-child {
      .ship-dot {
        background-color: #ec3a33;
      }

      p {
        color: #ec3a33;
      }
    }

    &:not(:last-child)::after {
      content: " ";
      position: absolute;
      z-index: 1;
      pointer-events: none;
      background-color: #e5e5e5;
      width: 1px;
      top: 37px;
      left: 10px;
      bottom: -20px;
    }
  }

  .ship-dot {
    position: relative;
    line-height: 20px;
    font-size: 10px;
    text-align: center;
    color: #666;
    margin: 24px 15px 0 5px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #ccc;
  }

  .ship-msg {
    flex: 1;
    padding: 15px 0;
  }

  .ship-msg-text {
    color: #333;
    font-size: 14px;
    line-height: 18px;
  }

  .ship-msg-time {
    font-size: 12px;
    color: #999;
  }
}

.table-cont {
  margin-bottom: 0;
  font-size: 13px;
  width: 100%;
  max-width: 100%;

  .detail-tr1 {
    font-size: 12px;
    color: #a6a6a6;
  }
}

.table-cont.order-table td {
  border-top: 0;
}

.table-cont>tbody>tr>td {
  line-height: 1.42857;
  padding: 8px;
  vertical-align: middle;
}

.m-l-md {
  margin-left: 20px !important;
}

.order-detail-container {
  background: #fff;
  position: relative;
  padding: 20px;
  border-radius: 5px;
}

.buttonbc {
  float: right;
  height: 40px;
}

.cont {
  /* width: 1400px; */
  /* margin-left: 80px; */
  margin-left: 30px;
  padding-bottom: 20px;
}

/deep/ .is-leaf {
  /* border: 1px solid #000000 !important; */
  box-sizing: border-box;
  text-align: center;
  font-size: 16px;
}

/deep/ .el-table__row td {
  /* border: 1px solid #000000 !important; */
  text-align: center;
  font-size: 16px;
}

/deep/ .el-table--border {
  /* border: 1px solid #000000 !important; */
}

.zongji {
  width: 100%;
  font-size: 26px;
  text-align: right;
  padding-top: 30px;
}

.sendhuo {
  margin-top: 60px;
  float: right;
}

.goods-image {
  width: 50px;
  height: 50px;
}</style>

